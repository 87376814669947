<template>
    <div>
        <div class="page-title">小程序认证</div>

        <com-auth :app="app" :company-info="companyInfo" @success="authSuccess" com/>

    </div>
</template>

<script>

import ComAuth from "@/views/AST/Reg/Com/Auth.vue";
import api from "@/repo/api";

export default {
    name: "Auth",
    components: {
        ComAuth
    },
    data() {
        return {
            routeMatch: "/ast/xcx/base",
            companyInfo: null,
        }
    },
    props: {
        app: Object
    },
    mounted() {
        this.refreshCompanyInfo()
    },
    methods: {
        authSuccess(data) {
            console.log(data);
            this.$router.push('/ast/xcx/base')
        },
        refreshCompanyInfo() {
            return new Promise((resolve) => {

                this.$loading.show()

                api.get('/ast-reg/current-company-info', (data) => {

                    this.$loading.hide()

                    this.companyInfo = data.data

                    if (this.companyInfo instanceof Array) {
                        this.companyInfo = {}
                    }

                    resolve(this.companyInfo)
                })

            });
        }
    }
}
</script>

<style scoped lang="less">

</style>